<template>
  <div class="columnd gap24">
    <div class="columnd gap4">
      <div class="selectCoin columnd gap4 w50">
        <span class="f12-700 c-darkGrey text-start">I Want to Buy</span>
        <div class="row w100 space-between align-center">
          <div class="rowd align-center gap4">
            <img src="../../assets/test/ETHEREUM.png" alt="" class="coin24" />
            <span class="f14-400 c-medGrey">ETH</span>
          </div>
          <inline-svg
            class="medgrey-svg"
            :src="require('../../assets/Icons/arrow.svg')"
          />
        </div>
      </div>
      <div class="rowd">
        <span class="f12-400 c-darkGrey">1 ETH=</span>
        <span class="f12-700 c-black">87,478.4</span>
        <span class="f12-400 c-darkGrey">USD</span>
      </div>
    </div>
    <div class="columnd gap8">
      <div class="rowd space-between gap12">
        <div class="selectCoin columnd gap4" style="flex: 1">
          <span class="f12-700 c-darkGrey text-start"
            >I want to pay for it via</span
          >
          <div class="row w100 space-between align-center">
            <div class="rowd align-center gap4">
              <span class="f14-400 c-medGrey">Bank Transfer</span>
            </div>
            <inline-svg
              class="medgrey-svg"
              :src="require('../../assets/Icons/close.svg')"
            />
          </div>
        </div>
        <div class="selectCoin columnd gap4" style="flex: 1">
          <span class="f12-700 c-darkGrey text-start">Preferred Currency</span>
          <div class="row w100 space-between align-center">
            <div class="rowd align-center gap4">
              <span class="f14-400 c-medGrey">USD(US Dollar)</span>
            </div>
            <inline-svg
              class="medgrey-svg"
              :src="require('../../assets/Icons/arrow.svg')"
            />
          </div>
        </div>
      </div>
      <div class="rowd gap8 align-center">
        <inline-svg :src="require('../../assets/Icons/Warning.svg')" />
        <p class="f12-400 c-darkGrey text-start">
          We have disabled the ability to set your own trade instructions and
          offer terms for Bank Transfer trades. Bank Transfer offer terms are
          now preset by Paxful. If these offer terms aren't suitable, please
          select "Other Bank Transfer" as your Payment Method.
        </p>
      </div>
    </div>
    <div class="rowd gap4">
      <input type="checkbox" name="" id="" />
      <span class="f14-400 c-darkGrey"
        >I accept the new terms for Bank Transfers</span
      >
    </div>
    <div class="selectCoin columnd gap4" style="flex: 1">
      <span class="f12-700 c-darkGrey text-start">Banks </span>
      <div class="row w100 space-between align-center">
        <div class="rowd align-center gap4">
          <span class="f14-400 c-medGrey">Bank Transfer</span>
        </div>
        <inline-svg
          class="medgrey-svg"
          :src="require('../../assets/Icons/arrow.svg')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Step1",
};
</script>

<style lang="scss" scoped></style>
