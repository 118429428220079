<template>
  <div class="columnd gap32">
    <div class="rowd gap24">
      <div style="flex: 1" class="selectCoin rowd gap16">
        <div class="option-check" @click="price = 'MarketPrice'">
          <div :class="{ opa0: price === 'FixedPrice' }" class="check"></div>
        </div>
        <div class="columnd gap8">
          <span class="f14-700 c-darkGrey text-start">Market Price</span>
          <p class="f12-400 c-medGrey text-start">
            Your offer’s selling price will change according to the market price
            of Bitcoin.
          </p>
        </div>
      </div>
      <div style="flex: 1" class="selectCoin rowd gap16">
        <div class="option-check" @click="price = 'FixedPrice'">
          <div :class="{ opa0: price === 'MarketPrice' }" class="check"></div>
        </div>
        <div class="columnd gap8">
          <span class="f14-700 c-darkGrey text-start">Fixed Price</span>
          <p class="f12-400 c-medGrey text-start">
            Your offer’s selling price is locked when you create it, and wont
            change with the market price.
          </p>
        </div>
      </div>
    </div>
    <div class="columnd gap16">
      <h2 class="f14-700 c-darkBlue text-start">Offer Trade Limits</h2>
      <div class="selectCoin rowd space-between select-amounttype">
        <span
          :class="{ selected: amountType === 'FixedAmount' }"
          @click="amountType = 'FixedAmount'"
          style="flex: 1"
          class="f12-700 c-medGrey"
          >FixedAmount</span
        >
        <span
          :class="{ selected: amountType === 'Range' }"
          @click="amountType = 'Range'"
          style="flex: 1"
          class="f12-700 c-medGrey"
          >Range</span
        >
        <div
          class="move-bg"
          :class="[
            { fixed: amountType === 'FixedAmount' },
            { range: amountType === 'Range' },
          ]"
        ></div>
      </div>
      <div style="max-width: 364px" class="selectCoin columnd gap8">
        <span class="f12-700 c-darkGrey text-start">Amount</span>
        <div class="rowd space-between align-center">
          <div class="tags">
            <span class="f12-400 c-darkGrey">10 USD</span>
          </div>
          <inline-svg
            class="medgrey-svg"
            :src="require('../../assets/Icons/arrow.svg')"
          />
        </div>
      </div>
      <div class="warning rowd gap gap8 align-start">
        <inline-svg :src="require('../../assets/Icons/Warning.svg')" />
        <div class="columnd gap8">
          <p class="f12-400 c-darkGrey text-start">
            Please provide ID and proof of address to increase your trade limit
            to 50,000.00 USD
          </p>
          <p class="f12-400 c-lightOrange text-start">
            To make this offer visible, be sure to have the minimum amount
            you’ve set within your wallet.
          </p>
        </div>
      </div>
    </div>
    <div class="columnd gap8">
      <h2 class="f14-700 c-darkBlue text-start">Offer Margin</h2>
      <div class="rowd">
        <p class="f14-400 c-black align-center space-between w100">
          This percentage determines how much you will earn or lose on each
          trade
        </p>
        <div class="selectCoin rowd gap32 align-center">
          <inline-svg
            class="medgrey-svg"
            :src="require('../../assets/Icons/minus.svg')"
          />
          <span class="f16-400 c-medGrey"> 45% </span>
          <inline-svg
            class="medgrey-svg"
            :src="require('../../assets/Icons/plus.svg')"
          />
        </div>
      </div>
      <p class="f12-400 c-darkGrey text-start">
        Current Bitcoin market price: 21,415.71 USD
      </p>
      <p class="f12-400 c-darkGrey text-start">
        So for every <span class="f12-700 c-ddGrey">25.00 USD</span> (your
        minimum trade limit), you will receive
        <span class="f12-700 c-ddGrey">23.50</span> USD worth of Bitcoin in
        return.
      </p>
    </div>
    <div class="columnd gap8">
      <h2 class="f14-700 c-darkBlue text-start">Offer Time Limits</h2>
      <div class="rowd">
        <p class="f14-400 c-black align-center space-between w100 text-start">
          This is how much time your trade partner has to make the payment and
          click Paid before the trade is automatically canceled.
        </p>
        <div class="selectCoin rowd gap32 align-center">
          <inline-svg
            class="medgrey-svg"
            :src="require('../../assets/Icons/minus.svg')"
          />
          <span class="f16-400 c-medGrey"> 90min </span>
          <inline-svg
            class="medgrey-svg"
            :src="require('../../assets/Icons/plus.svg')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Step2",
  data() {
    return {
      price: "MarketPrice",
      amountType: "FixedAmount",
    };
  },
};
</script>

<style lang="scss" scoped>
.select-amounttype {
  max-width: 234px;
  height: 50px;
  align-items: center;
  span {
    z-index: 1;
  }
}
.fixed {
  left: 10px;
}
.range {
  left: 52%;
}
.move-bg {
  background: white !important;
  position: absolute;
  width: 43%;
  height: 40px;
  top: 5px;
  border-radius: 4px;
  z-index: 0;
  transition: 500ms;
}
.selected {
  color: var(--darkBlue) !important;
}

.option-check {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  border: 2px solid var(--darkBlue);
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  .check {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: var(--black);
  }
}
</style>
