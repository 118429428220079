<template>
  <div class="columnd w100 gap32">
    <p class="f14-400 darkGrey text-start">
      Start creating your offer by selecting the cryptocurrency you want to
      trade, whether or not you want to buy or sell, and the payment method you
      want to use.
    </p>
    <div class="rowd gap4">
      <span class="f14-400 c-darkGrey">You want to buy</span>
      <span class="f14-700 c-black">Bitcoin (BTC)</span>
    </div>
    <div class="rowd gap4">
      <span class="f14-400 c-darkGrey">And pay for it via</span>
      <span class="f14-700 c-black">Bank Transfer</span>
      <span class="f14-400 c-darkGrey">In</span>
      <span class="f14-700 c-black">USD</span>
    </div>
    <div v-if="step > 1" class="rowd gap4">
      <p class="f14-400 c-darkGrey text-start">
        You will pay <span class="f14-700 c-black">10,00%</span> below market
        price on every purchase
      </p>
    </div>
    <p v-if="step > 2" class="f14-400 c-darkGrey text-start">
      Only trusted users that meet your required minimum number of trades can
      begin a trade with you.
    </p>
    <div class="rowd w100 space-between gap24">
      <button @click="stepUp" :disabled="step === 1" class="secondary-btn">
        Back
      </button>
      <button @click="stepDown" class="primary-btn">Next</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["step"],
  name: "Terms",
  methods: {
    stepUp() {
      this.$emit("stepUp");
    },
    stepDown() {
      this.$emit("stepDown");
    },
  },
};
</script>

<style lang="scss" scoped>
.secondary-btn:disabled {
  border-color: var(--medGrey) !important;
  color: var(--medGrey) !important;
  background-color: transparent !important;
}
</style>
