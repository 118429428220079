<template>
  <div class="container rowd space-between gap24">
    <div style="flex: 2.4; gap: 40px" class="mini-container">
      <div class="title columnd gap16">
        <h1 class="f22-700 fBold c-lightOrange text-start">Create Offer</h1>
        <div class="btns rowd">
          <button
            @click="buy = true"
            :class="{ 'toggle-active': buy }"
            class="toggle"
          >
            <span class="f16-700"> Buy </span>
            <transition name="borderrotate">
              <span v-if="buy" class="underline"></span>
            </transition>
          </button>

          <button
            @click="buy = false"
            :class="{ 'toggle-active': !buy }"
            class="toggle"
          >
            <span class="f16-700"> Sell </span>
            <transition name="borderrotate">
              <span v-if="!buy" class="underline"></span>
            </transition>
          </button>
        </div>
        <div class="rowd gap12 w100 align-center">
          <div style="flex: 1" class="rowd gap4 align-center steps active">
            <inline-svg
              :src="require('../../assets/Icons/paymentMethod.svg')"
            />
            <span>Payment Method</span>
          </div>
          <div style="flex: 1" class="line">
            <div :class="step > 1 ? 'line-active' : ''" class="light"></div>
          </div>
          <div
            style="flex: 1"
            :class="step > 1 ? 'active' : ''"
            class="rowd gap4 align-center steps"
          >
            <inline-svg :src="require('../../assets/Icons/pricing.svg')" />
            <span>Payment Method</span>
          </div>
          <div style="flex: 1" class="line">
            <div :class="step === 3 ? 'line-active' : ''" class="light"></div>
          </div>
          <div
            style="flex: 1"
            :class="step === 3 ? 'active' : ''"
            class="rowd gap4 align-center steps"
          >
            <inline-svg :src="require('../../assets/Icons/setting.svg')" />
            <span>Payment Method</span>
          </div>
        </div>
      </div>
      <Step1 :key="buy + '1'" :buy="buy" v-if="step === 1" />
      <Step2 :key="buy + '2'" :buy="buy" v-if="step === 2" />
      <Step3 :key="buy + '3'" :buy="buy" v-if="step === 3" />
    </div>
    <div style="flex: 1" class="mini-container gap32">
      <h1 class="f16-700 fBold c-black text-start">Payment Method</h1>
      <Terms @stepUp="down" @stepDown="up" :step="step" />
    </div>
  </div>
</template>

<script>
import Terms from "./Terms";
import Step1 from "./Step1.vue";
import Step2 from "./Step2.vue";
import Step3 from "./Step3.vue";
export default {
  name: "CreateOffer",
  components: {
    Terms,
    Step1,
    Step2,
    Step3,
  },
  data() {
    return {
      buy: true,
      step: 2,
    };
  },
  methods: {
    up() {
      if (this.step < 3) {
        this.step++;
      }
    },
    down() {
      if (this.step > 1) {
        this.step--;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.line {
  height: 4px;
  background-color: var(--lightGrey);
  .light {
    height: 4px;
    width: 0%;
    transition: 1s;
    background-color: var(--lightCryan);
    border-radius: 24px;
  }
  .line-active {
    width: 100% !important;
  }
}
.active {
  span {
    color: var(--lightCryan) !important;
  }
  svg {
    fill: var(--lightCryan) !important;
  }
}
.steps {
  span {
    font-size: clamp(12px, 1vw, 14px);
    font-weight: 700;
    color: var(--medGrey);
  }
  svg {
    width: 28px;
    height: 28px;
    fill: var(--medGrey);
  }
}
.mini-container {
  padding: 32px !important;
  display: flex;
  flex-direction: column;
}
.container {
  min-height: 100vh;
  padding: 80px 120px;
  align-items: flex-start;
}
</style>
